import AutoComplete from '@tarekraafat/autocomplete.js'
import $ from 'jquery'

export default class AddressAutoComplete {
  constructor (addressId, suburbId, cityId) {
    this.autocomplete_settings = {
      debounce:   200,
      diacritics: true,
    }
    this.addressId = addressId
    this.suburbId = suburbId
    this.cityId = cityId
  }

  init () {
    this.addressField = document.querySelector(this.addressId)
    this.suburbField = document.querySelector(this.suburbId)
    this.cityField = document.querySelector(this.cityId)

    if (!this.addressField || !this.suburbField || !this.cityField) {
      return
    }

    this._fetchSuburbNames()

    this._setupAddressAutoComplete()
    this._setupSuburbAutoComplete()
    this._setupCityAutoComplete()
  }

  async _fetchAutocompleteData (query, endpoint) {
    try {
      const response = await fetch(
        `/api/autocomplete/${endpoint}?query=${encodeURIComponent(query)}`,
      )
      const data = await response.json()
      return data.items
    } catch (error) {
      return []
    }
  }

  async _fetchSuburbNames () {
    const response = await fetch('/api/autocomplete/suburbs/all')
    const json = await response.json()
    this.suburbField.dataset.matchesList = JSON.stringify(json.items)
  }

  _setupAddressAutoComplete () {
    this.addressAutoComplete = new AutoComplete({
      ...this.autocomplete_settings,
      selector:    this.addressId,
      placeHolder: 'Search for an address...',
      data:        {
        src: async (query) => {
          return await this._fetchAutocompleteData(query, 'addresses')
        },
      },
      searchEngine: (query, record) => record._source.full_address,
      events:       {
        input: {
          selection: (event) => {
            const selection = event.detail.selection
            const source = selection.value._source

            this.addressField.value = source.full_address_number + ' ' + source.full_road_name
            this.suburbField.value = source.suburb_locality
            this.cityField.value = source.town_city

            this._revalidateAddressFields()
          },
        },
      },
    })
  }

  _setupSuburbAutoComplete () {
    this.suburbAutoComplete = new AutoComplete({
      ...this.autocomplete_settings,
      selector:    this.suburbId,
      placeHolder: 'Search for a suburb...',
      data:        {
        src: async (query) => {
          return await this._fetchAutocompleteData(query, 'suburbs')
        },
      },
      searchEngine: (query, record) => {
        // major name is the town or city, only list if it's different from the suburb name
        if (record._source.major_name !== null && record._source.major_name !== record._source.name) {
          return record._source.name + ', ' + record._source.major_name
        } else {
          return record._source.name
        }
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection
            const source = selection.value._source

            this.suburbField.value = source.name
            this.cityField.value = source.major_name

            this._revalidateAddressFields()
          },
        },
      },
    })
  }

  _setupCityAutoComplete () {
    this.cityAutoComplete = new AutoComplete({
      ...this.autocomplete_settings,
      selector:    this.cityId,
      placeHolder: 'Search for a town or city...',
      data:        {
        src: async (query) => {
          return await this._fetchAutocompleteData(query, 'towns_cities')
        },
      },
      searchEngine: (query, record) => {
        return record._source.name
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection
            const source = selection.value._source

            this.cityField.value = source.name

            this._revalidateAddressFields()
          },
        },
      },
    })
  }

  _revalidateAddressFields () {
    $(this.suburbField).closest('form').foundation('validateInput', $(this.suburbField))
    $(this.cityField).closest('form').foundation('validateInput', $(this.cityField))
  }
}
