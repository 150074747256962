// This removes some validations on the form when Partial record is selected and puts back validation when Full client record is selected

import $ from 'jquery'

let requiredFields = []

export default function () {
  requiredFields = $('.js-required-if-complete')

  const clientTypeSelection = '.client-form__field--client-type input[type="radio"]'

  $(clientTypeSelection).on('change', function (e) {
    if (e.target.id === 'client_complete_false') {
      $('.callout.client-type-callout').removeClass('hide')

      removeValidation()
    } else {
      $('.callout.client-type-callout').addClass('hide')

      putBackValidation()
    }
  })

  if ($('#client_complete_false').is(':checked')) {
    $(clientTypeSelection).trigger('change')
  }
}

const removeValidation = () => {
  requiredFields.each(function () {
    $(this).find('.form-field-layout__required-asterix').addClass('hide')

    $(this).find('input, select').each(function (_idx, input) {
      if ($(input).attr('data-validator') !== undefined) {
        $(input).removeAttr('required')
      } else {
        $(input).attr('data-abide-ignore', true)
      }
    })
  })
}

const putBackValidation = () => {
  requiredFields.each(function () {
    $(this).find('.form-field-layout__required-asterix').removeClass('hide')

    $(this).find('input, select').each(function (_idx, input) {
      if ($(input).attr('data-validator') !== undefined) {
        $(input).attr('required', true)
      } else {
        $(input).removeAttr('data-abide-ignore')
      }
    })
  })
}
