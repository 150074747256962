import PartnerFieldGroupCanceller from '~/javascript/application/client/PartnerFieldGroupCanceller'
import MentorPermissionSelector from '~/javascript/application/client/MentorPermissionSelector'
import AddressAutoComplete from '~/javascript/application/client/AddressAutoComplete'

export default function () {
  const sectionToToggle = document.querySelector('.form-section-to-toggle-container')

  const partnerFieldGroupCanceller = new PartnerFieldGroupCanceller(sectionToToggle)
  partnerFieldGroupCanceller.init()

  const mentorPermissionSelector = new MentorPermissionSelector('.client-form__field--allowed-mentor-ids')
  mentorPermissionSelector.init()

  const addressAutoComplete = new AddressAutoComplete('#client_address', '#client_suburb', '#client_city')
  addressAutoComplete.init()
}
